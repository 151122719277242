<template>
  <div class="washing-table" v-loading="loading">
    <el-radio-group v-model="searchForm.feeType" @change="changeRadio">
      <el-radio-button label="5">退款列表</el-radio-button>
      <el-radio-button label="6">收款列表</el-radio-button>
    </el-radio-group>
    <div class="search">
      <Search
        ref="search"
        :search-data="searchData"
        @searchChange="searchChange"
      />
    </div>
    <div class="table">
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '5'">
        衣物件数：{{ total ? total : 0 }}件
        <span style="padding-left:20px"
          >跨店通享卡卡消金额：{{ totalPirce ? totalPirce : 0 }}元</span
        >
      </div>
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '6'">
        衣物件数：{{ total ? total : 0 }}件
        <span style="padding-left:20px"
          >跨店通享卡卡消退款金额：{{ totalPirce ? totalPirce : 0 }}元</span
        >
      </div>
      <Table
        :serial-number="true"
        :key="searchForm.feeType"
        :current-value="searchForm.current"
        :header-style="headerStyle"
        :orientation="'center'"
       :tooltipShow="true"
        :height="'calc(100vh - 370px)'"
        :table-data="tableData"
        :title-list="searchForm.feeType == '5' ? titleListData : titleListData2"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import { getAllServiceList } from "@/api/clothes/clothes";
import {
  getFinancialBillUniversalFee,
  exportUniversalFeel,
} from "@/api/store/accounting";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      searchData: [
        {
          prop: "orderNumber",
          label: "查询衣物",
          type: "text",
          text: "订单号/衣物号/手机号",
        },
        {
          prop: "serviceId",
          label: "服务",
          type: "select",
          selectArr: [],
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
            {
              name: "导出",
              code: "export",
              type: "primary",
              loading: false,
            },
          ],
          labelWidth:'20px'
        },
      ],
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      tableData: [],
      titleListData: [
        {
          prop: "orderNumber",
          label: "订单号",
          width: "150",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "150",
        },
        {
          prop: "userName",
          label: "用户",
          width: "120",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: "150",
        },
        {
          prop: "serviceName",
          label: "服务",
          width: "120",
        },
        {
          prop: "orderPrice",
          label: "订单实收（元）",
          width: "150",
        },
        {
          prop: "clothesPrice",
          label: "衣物实收（元）",
          width: "150",
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
          width: "120",
        },
        {
          prop: "clothesStatusDesc",
          label: "衣物状态",
          width: "120",
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: "150",
        },
        {
          prop: "payTime",
          label: "支付时间",
          width: "150",
        },
        {
          prop: "storeName",
          label: "操作门店",
          width: "150",
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: "150",
        },
      ],
      titleListData2: [
        {
          prop: "orderNumber",
          label: "订单号",
          width: "150",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "150",
        },
        {
          prop: "userName",
          label: "用户",
          width: "120",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: "150",
        },
        {
          prop: "serviceName",
          label: "服务",
          width: "120",
        },
        {
          prop: "orderPrice",
          label: "订单实收（元）",
          width: "150",
        },
        {
          prop: "clothesPrice",
          label: "衣物实收（元）",
          width: "150",
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
          width: "120",
        },
        {
          prop: "clothesStatusDesc",
          label: "衣物状态",
          width: "120",
        },
        {
          prop: "refundPrice",
          label: "退款金额（元）",
          width: "150",
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: "150",
        },
        {
          prop: "payTime",
          label: "支付时间",
          width: "150",
        },
        {
          prop: "refundClothesTime",
          label: "退款时间",
          width: "150",
        },
        {
          prop: "storeName",
          label: "操作门店",
          width: "150",
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: "150",
        },
      ],
      total: 0,
      totalPirce: 0,
      searchForm: {
        size: 10,
        current: 1,
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
          this.searchForm.feeType = 5;
          this.searchForm.financialBillId = value.query.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getAllServiceListData();
    this.getFinancialBillUniversalFeeList();
  },
  methods: {
    //  获取通享卡卡消明细
    getFinancialBillUniversalFeeList() {
      this.loading = true;
      getFinancialBillUniversalFee({ ...this.searchForm })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records;
            this.totalPirce = data.data.totalSum;
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换标签触发
    changeRadio() {
      this.searchForm = {
        size: 10,
        current: 1,
        feeType: this.searchForm.feeType,
        financialBillId: this.searchForm.financialBillId,
      };
      this.$refs.search.queryInfo("resetData");
      this.getFinancialBillUniversalFeeList();
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.getFinancialBillUniversalFeeList();
      } else if (code == "reset") {
        this.searchForm = {
          financialBillId: this.searchForm.financialBillId,
          size: 10,
          current: 1,
          feeType: this.searchForm.feeType,
        };
        this.getFinancialBillUniversalFeeList();
      } else if (code == "export") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.searchData[this.searchData.length - 1].typeList[2].loading = true;
        exportUniversalFeel({
          financialBillId: this.searchForm.financialBillId,
          feeType: this.searchForm.feeType,
          orderNumber: this.searchForm.orderNumber,
          serviceId: this.searchForm.serviceId,
        })
          .then((response) => {
            if (response.status !== 200)
              return this.$message.error("导出失败,请重试!");
            const filename = decodeURI(
              response.headers["content-disposition"]
                .split(";")[1]
                .split("= ")[1]
            );
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$message.success("导出成功!");
            document.body.removeChild(link);
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          })
          .catch(() => {
            this.$message.error("导出失败!");
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          });
      }
    },
    // 获取衣物服务类别请求
    getAllServiceListData() {
      getAllServiceList().then(({ data }) => {
        this.searchData[1].selectArr = data.data?.map((item) => {
          item.id = item.serviceId;
          item.name = item.serviceName;
          return item;
        });
      });
    },
    // 改变页码触发
    handleCurrentChange(val) {
      this.searchForm.current = val;
      this.getFinancialBillUniversalFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.washing-table {
  .search {
    margin: 10px 0;
  }
  .table {
    font-size: 14px;
  }
}
</style>
