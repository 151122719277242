<template>
  <div class="transaction-detail content-index">
    <el-page-header @back="goBack" style="margin:10px 0">
      <template slot="content">
        <div class="title">交易明细</div>
      </template>
    </el-page-header>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="洗涤费用明细" name="first">
          <WashingTable v-if="tabRefresh.first" />
        </el-tab-pane>
        <el-tab-pane label="通享卡充/退明细" name="two">
          <CardStoredValueTable v-if="tabRefresh.two" />
        </el-tab-pane>
        <el-tab-pane label="本店通享卡卡消明细" name="three">
          <CardConsumeTable v-if="tabRefresh.three" />
        </el-tab-pane>
        <el-tab-pane label="总部通享卡卡消明细" name="seven">
          <HeadquartersCardConsumeTable v-if="tabRefresh.seven" />
        </el-tab-pane>
        <el-tab-pane label="跨店通享卡卡消明细" name="ten">
          <CrossStoreRewardsCard v-if="tabRefresh.ten" />
        </el-tab-pane>
        <el-tab-pane label="本店通享跨店卡消明细" name="eleven">
          <OurStoreRewardsCardCrossStore v-if="tabRefresh.eleven" />
        </el-tab-pane>
        <el-tab-pane label="权益卡明细" name="four">
          <EquityCardTable v-if="tabRefresh.four" />
        </el-tab-pane>
        <el-tab-pane label="美团明细" name="five">
          <MeituanTable v-if="tabRefresh.five" />
        </el-tab-pane>
        <el-tab-pane label="抖音明细" name="nine">
          <DouyinTable v-if="tabRefresh.nine" />
        </el-tab-pane>
        <el-tab-pane label="小程序明细" name="six">
          <WeixinTable v-if="tabRefresh.six" />
        </el-tab-pane>
        <el-tab-pane label="转余额明细" name="neight">
          <TransferBalanceTable v-if="tabRefresh.neight" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import WashingTable from "@/view/accounting/components/transactionDetail/components/washingTable";
import CardStoredValueTable from "@/view/accounting/components/transactionDetail/components/cardStoredValueTable";
import CardConsumeTable from "@/view/accounting/components/transactionDetail/components/cardConsumeTable";
import EquityCardTable from "@/view/accounting/components/transactionDetail/components/equityCardTable";
import MeituanTable from "@/view/accounting/components/transactionDetail/components/meituanTable";
import WeixinTable from "@/view/accounting/components/transactionDetail/components/weixinTable";
import TransferBalanceTable from "@/view/accounting/components/transactionDetail/components/transferBalanceTable";
import HeadquartersCardConsumeTable from "@/view/accounting/components/transactionDetail/components/headquartersCardConsumeTable";
import DouyinTable from "@/view/accounting/components/transactionDetail/components/douyinTable";
import CrossStoreRewardsCard from "@/view/accounting/components/transactionDetail/components/crossStoreRewardsCard";
import OurStoreRewardsCardCrossStore from "@/view/accounting/components/transactionDetail/components/ourStoreRewardsCardCrossStore";

export default {
  components: {
    WashingTable,
    CardStoredValueTable,
    CardConsumeTable,
    EquityCardTable,
    MeituanTable,
    DouyinTable,
    WeixinTable,
    TransferBalanceTable,
    HeadquartersCardConsumeTable,
    CrossStoreRewardsCard,
    OurStoreRewardsCardCrossStore,
    
    
  },
  data() {
    return {
      activeName: "first",
      tabRefresh: {
        first: true,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        neight: false,
        seven: false,
        nine: false,
        ten: false,
        eleven: false,
      },
      financialBillId: null,
    };
  },
  beforeRouteLeave(to, form, next) {
    if (to.name != "accountDetails") {
      this.$confirm("你确定离开该页面吗？", "提示", {
        confirmButtonText: "确定离开",
        cancelButtonText: "我再看看",
        type: "warning",
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    } else {
      next();
    }
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
          this.financialBillId = value.query.id;
          this.loginBool = value.query.bool;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/home/accountDetails",
        query: {
          id: this.financialBillId,
          bool: this.loginBool,
        },
      });
    },
    handleClick(tab) {
      this.activeName = tab.name;
      switch (this.activeName) {
        case "first":
          this.switchTab("first");
          break;
        case "two":
          this.switchTab("two");
          break;
        case "three":
          this.switchTab("three");
          break;
        case "four":
          this.switchTab("four");
          break;
        case "five":
          this.switchTab("five");
          break;
        case "six":
          this.switchTab("six");
          break;
        case "neight":
          this.switchTab("neight");
          break;
        case "seven":
          this.switchTab("seven");
          break;
        case "nine":
          this.switchTab("nine");
          break;
        case "ten":
          this.switchTab("ten");
          break;
        case "eleven":
          this.switchTab("eleven");
          break;
      }
    },
    switchTab(tab) {
      for (const key in this.tabRefresh) {
        if (key === tab) {
          this.tabRefresh[key] = true;
        } else {
          this.tabRefresh[key] = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-detail {
  position: relative;
  padding: 10px 30px 0 30px;
  overflow: auto;
  .title {
    font-size: 20px;
    color: rgba(16, 16, 16, 100);
  }
  .content {
    padding: 0 10px;
  }
}
</style>
