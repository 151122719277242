<template>
  <div class="washing-table" v-loading="loading">
    <el-radio-group v-model="searchForm.feeType" @change="changeRadio">
      <el-radio-button label="1">收衣列表</el-radio-button>
      <el-radio-button label="2"> 退衣列表</el-radio-button>
    </el-radio-group>
    <div class="search">
      <Search
        ref="search"
        :search-data="
          searchForm.feeType == '1'
            ? searchData
            : [...searchData.slice(0, 2), ...searchData.slice(3)]
        "
        @searchChange="searchChange"
      />
    </div>
    <div class="table">
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '1'">
        <span> 收衣件数：{{ total ? total : 0 }}件</span>
        <span style="padding-left: 20px">
          总洗涤费：{{ totalSum ? totalSum : 0 }}元
        </span>
      </div>
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '2'">
        <span> 退衣件数：{{ total ? total : 0 }}件</span>
        <span style="padding-left: 20px">
          总退款洗涤费：{{ totalSum ? totalSum : 0 }}元
        </span>
      </div>
      <Table
        :serial-number="true"
        :key="searchForm.feeType"
        :current-value="searchForm.current"
        :header-style="headerStyle"
       :tooltipShow="true"
        :orientation="'center'"
        :height="'calc(100vh - 370px)'"
        :table-data="tableData"
        :title-list="searchForm.feeType == '1' ? titleListData : titleListData2"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import { getAllServiceList } from "@/api/clothes/clothes";
import {
  getFinancialBillWashFee,
  exportWashFeel,
} from "@/api/store/accounting";
export default {
  components: {
    Search,
    Table,
  },

  data() {
    return {
      searchData: [
        {
          prop: "orderNumber",
          label: "查询衣物",
          type: "text",
          text: "订单号/衣物号/手机号",
        },
        {
          prop: "serviceId",
          label: "服务",
          type: "select",
          selectArr: [],
        },
        {
          prop: "equityDisposableCoupon",
          label: "权益免洗券",
          type: "select",
          selectArr: [
            {
              id: 1,
              name: "未使用",
            },
            {
              id: 2,
              name: "已使用",
            },
          ],
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
            {
              name: "导出",
              code: "export",
              type: "primary",
              loading: false,
            },
          ],
           labelWidth:'20px'
        },
      ],
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      tableData: [],
      titleListData: [
        {
          prop: "storeName",
          label: "门店",
          width: 150,
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: 120,
        },
        {
          prop: "userName",
          label: "用户",
           width: 150,
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: 170,
        },
        {
          prop: "serviceName",
          label: "服务",
          width: 150,
        },
        {
          prop: "factoryName",
          label: "工厂",
          width: 120,
        },
        {
          prop: "clothesPrice",
          label: "衣服金额（元）",
          width: 150,
        },
        {
          prop: "payStatusDesc",
          label: "支付状态",
          width: 120,
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
          width: 120,
        },
        {
          prop: "clothesStatusDesc",
          label: "衣物状态",
          width: 120,
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: 170,
        },
        {
          prop: "equityCouponDesc",
          label: "权益免洗券",
          width: 120,
        },
        {
          prop: "squareNum",
          label: "平方数",
          width: 120,
        },
        {
          prop: "costPrice",
          label: "洗涤成本（元）",
          width: 120,
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: 150,
        },
      ],
      titleListData2: [
        {
          prop: "storeName",
          label: "门店",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: 120,
        },
        {
          prop: "userName",
          label: "用户",
          width: 120,
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: 150,
        },
        {
          prop: "serviceName",
          label: "服务",
        },
        {
          prop: "factoryName",
          label: "工厂",
          width: 150,
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
        },
        {
          prop: "clothesStatusDesc",
          label: "衣物状态",
          width: 150,
        },
        {
          prop: "equityCouponDesc",
          label: "权益免洗券",
          width: 150,
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: 150,
        },
        {
          prop: "refundClothesTime",
          label: "退衣时间",
          width: 150,
        },
        {
          prop: "costPrice",
          label: "退回成本（元）",
          width: 150,
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: 150,
        },
      ],
      total: 0,
      totalSum: 0,
      searchForm: {
        size: 10,
        current: 1,
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
          this.searchForm.feeType = value.query.status;
          this.searchForm.financialBillId = value.query.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getAllServiceListData();
  },
  mounted() {
    this.getFinancialBillWashFeeList();
  },
  methods: {
    // 获取表格数据
    getFinancialBillWashFeeList() {
      this.loading = true;
      getFinancialBillWashFee({ ...this.searchForm })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records;
            this.total = data.data.total;
            this.totalSum = data.data.totalSum;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换标签触发
    changeRadio() {
      this.searchForm = {
        size: 10,
        current: 1,
        feeType: this.searchForm.feeType,
        financialBillId: this.searchForm.financialBillId,
      };
      this.$refs.search.queryInfo("resetData");
      this.totalSum = 0;
      this.getFinancialBillWashFeeList();
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.getFinancialBillWashFeeList();
      } else if (code == "reset") {
        this.searchForm = {
          financialBillId: this.searchForm.financialBillId,
          size: 10,
          current: 1,
          feeType: this.searchForm.feeType,
        };
        this.getFinancialBillWashFeeList();
      } else if (code == "export") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.searchData[this.searchData.length - 1].typeList[2].loading = true;
        exportWashFeel({
          financialBillId: this.searchForm.financialBillId,
          feeType: this.searchForm.feeType,
          orderNumber: this.searchForm.orderNumber,
          serviceId: this.searchForm.serviceId,
          equityDisposableCoupon: this.searchForm.equityDisposableCoupon,
        })
          .then((response) => {
            if (response.status !== 200)
              return this.$message.error("导出失败,请重试!");
            const filename = decodeURI(
              response.headers["content-disposition"]
                .split(";")[1]
                .split("= ")[1]
            );
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$message.success("导出成功!");
            document.body.removeChild(link);
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          })
          .catch(() => {
            this.$message.error("导出失败!");
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          });
      }
    },
    // 获取衣物服务类别请求
    getAllServiceListData() {
      getAllServiceList().then(({ data }) => {
        this.searchData[1].selectArr = data.data?.map((item) => {
          item.id = item.serviceId;
          item.name = item.serviceName;
          return item;
        });
      });
    },
    // 改变页码触发
    handleCurrentChange(val) {
      this.searchForm.current = val;
      this.getFinancialBillWashFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.washing-table {
  .search {
    margin: 10px 0;
  }
  .table {
    font-size: 14px;
  }
}
</style>
