<template>
  <div class="equity-card-table" v-loading="loading">
    <el-radio-group v-model="searchForm.feeType">
      <el-radio-button label="1">转余额列表</el-radio-button>
    </el-radio-group>
    <div class="search">
      <Search :search-data="searchData" @searchChange="searchChange" />
    </div>
    <div class="table">
      <div style="padding-bottom:10px">
        转入笔数：{{ total?total:0 }} 笔
        <span style="padding-left:20px">转入金额：{{ totalPirce?totalPirce:0 }}元</span>
      </div>
      <Table
        :serial-number="true"
        :current-value="searchForm.current"
        :header-style="headerStyle"
        :orientation="'center'"
        :height="'calc(100vh - 370px)'"
        :tooltipShow="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import { getFinancialBillTransFee,exportTransferBalanceFeel } from "@/api/store/accounting";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      searchData: [
        {
          prop: "phone",
          label: "客户查询",
          type: "text",
          text: "输入手机号",
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
             {
              name: "导出",
              code: "export",
              type: "primary",
              loading:false
            },
          ],
           labelWidth:'20px'
        },
      ],
      formData: {
        size: 10,
        current: 1,
      },
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      tableData: [],
      titleListData: [
        {
          prop: "storeName",
          label: "历史余额门店",
            width: "150",
        },
        {
          prop: "userName",
          label: "用户",
          width: "150"
        },
        {
          prop: "phone",
          label: "手机号",
          width: "150",
        },
        {
          prop: "historyBalance",
          label: "历史余额（元）",
          width: "150",
        },
        {
          prop: "transferVoucher",
          label: "转至账户",
          width: "150",
        },
        {
          prop: "transferInBalance",
          label: "转入余额（元）",
          width: "150",
        },
        {
          prop: "operateTime",
          label: "操作时间",
          width: "150",
        },
        {
          prop: "operateStoreName",
          label: "操作门店",
          width: "150"
        },
        {
          prop: "operateUser",
          label: "操作人",
          width: "150"
        },
      ],

      total: 0,
      totalPirce: 0,
      searchForm: {
        size: 10,
        current: 1,
        feeType:1,
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
           this.searchForm.financialBillId=value.query.id
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
     this.getFinancialBillTransFeeList()
  },
  methods: {
    // 获取转余额明细
    getFinancialBillTransFeeList() {
      this.loading = true;
      getFinancialBillTransFee({ ...this.searchForm })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records.map((item) => {
              item.transferVoucher="通享卡余额"
              item.transferInBalance=item.historyBalance
              return item;
            });
            this.totalPirce= data.data.totalSum
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
       // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.getFinancialBillTransFeeList();
      } else if (code == "reset") {
        this.searchForm = {
          financialBillId: this.searchForm.financialBillId,
          size: 10,
          current: 1,
          feeType: 1,
        };
        this.getFinancialBillTransFeeList();
      }else if(code=='export'){
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.searchData[this.searchData.length-1].typeList[2].loading = true;
        exportTransferBalanceFeel({
          financialBillId: this.searchForm.financialBillId,
          phone: this.searchForm.phone,
        })
          .then((response) => {
            if (response.status !== 200)
              return this.$message.error("导出失败,请重试!");
            const filename = decodeURI(
              response.headers["content-disposition"]
                .split(";")[1]
                .split("= ")[1]
            );
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$message.success("导出成功!");
            document.body.removeChild(link);
            this.searchData[this.searchData.length-1].typeList[2].loading = false;
          })
          .catch(() => {
            this.$message.error("导出失败!");
            this.searchData[this.searchData.length-1].typeList[2].loading = false;
          });
      }
    },

    // 改变页码触发
    handleCurrentChange(val) {
      this.searchForm.current = val;
      this.getFinancialBillTransFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.equity-card-table {
  .search {
    margin: 10px 0;
  }
   .table {
    font-size: 14px;
  }
}
</style>
