<template>
  <div class="equity-card-table" v-loading="loading">
    <el-radio-group v-model="searchForm.feeType" @change="changeRadio">
      <el-radio-button label="1">收款列表</el-radio-button>
      <el-radio-button label="2">退款列表</el-radio-button>
    </el-radio-group>
    <div class="search">
      <Search
        :search-data="searchData"
        ref="search"
        @searchChange="searchChange"
      />
    </div>
    <div class="table">
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '1'">
        订单数量：{{ total ? total : 0 }} 笔
        <span style="padding-left:20px"
          >订单收入：{{ totalPirce ? totalPirce : 0 }}元</span
        >
      </div>
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '2'">
        退款件数：{{ total ? total : 0 }} 件
        <span style="padding-left: 20px">
          退款金额：{{ totalPirce ? totalPirce : 0 }}元
        </span>
      </div>
      <Table
        v-if="searchForm.feeType == '1'"
        :serial-number="true"
        :current-value="searchForm.current"
        :header-style="headerStyle"
        :orientation="'center'"
        :height="'calc(100vh - 370px)'"
        :tooltipShow="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
      <Table2
        v-if="searchForm.feeType == '2'"
        :serial-number="true"
        :current-value="searchForm.current"
        :header-style="headerStyle"
        :orientation="'center'"
        :height="'calc(100vh - 370px)'"
        :tooltipShow="true"
        :table-data="tableData"
        :title-list="titleListData2"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import Table2 from "@/components/table2";
import {
  getFinancialBillMeituanFee,
  exportMeituanFeel,
} from "@/api/store/accounting";
export default {
  components: {
    Search,
    Table,
    Table2,
  },
  data() {
    return {
      searchData: [
        {
          prop: "orderNumber",
          label: "查询衣物",
          type: "text",
          text: "订单号/衣物号/手机号",
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
            {
              name: "导出",
              code: "export",
              type: "primary",
              loading: false,
            },
          ],
           labelWidth:'20px'
        },
      ],
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      tableData: [],
      titleListData: [
        {
          prop: "storeName",
          label: "消费门店",
          width: "120",
        },
        {
          prop: "orderNumber",
          label: "订单号",
          width: "120",
        },
        {
          prop: "userName",
          label: "用户",
          width: "120",
        },
        {
          prop: "orderPrice",
          label: "验券金额（元）",
          width: "150",
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
          width: "100",
        },
        {
          prop: "orderStatusDesc",
          label: "订单状态",
          width: "100",
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: "150",
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: "120",
        },
      ],
      titleListData2: [
        {
          prop: "storeName",
          label: "消费门店",
          width: "120",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "120",
        },
        {
          prop: "userName",
          label: "用户",
          width: "120",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: "120",
        },
        {
          prop: "orderStatusDesc",
          label: "衣物状态",
          width: "100",
        },
        {
          prop: "orderPrice",
          label: "退款金额（元）",
          width: "120",
        },
        {
          prop: "collectClothesTime",
          label: "收衣时间",
          width: "150",
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
          width: "100",
        },
        {
          prop: "refundTime",
          label: "退款时间",
          width: "150",
        },
        {
          prop: "operateUserName",
          label: "操作人",
          width: "120",
        },
      ],
      total: 0,
      totalPirce: 0,
      searchForm: {
        size: 10,
        current: 1,
        feeType: 1,
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
          this.searchForm.feeType = value.query.status;
          this.searchForm.financialBillId = value.query.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getFinancialBillMeituanFeeList();
  },
  methods: {
    // 获取美团明细
    getFinancialBillMeituanFeeList() {
      this.loading = true;
      getFinancialBillMeituanFee({ ...this.searchForm })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records;
            this.totalPirce = data.data.totalSum;
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换标签触发
    changeRadio() {
      this.searchForm = {
        size: 10,
        current: 1,
        feeType: this.searchForm.feeType,
        financialBillId: this.searchForm.financialBillId,
      };
      this.$refs.search.queryInfo("resetData");
      this.getFinancialBillMeituanFeeList();
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.getFinancialBillMeituanFeeList();
      } else if (code == "reset") {
        this.searchForm = {
          financialBillId: this.searchForm.financialBillId,
          size: 10,
          current: 1,
          feeType: this.searchForm.feeType,
        };
        this.getFinancialBillMeituanFeeList();
      } else if (code == "export") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.searchData[this.searchData.length - 1].typeList[2].loading = true;
        exportMeituanFeel({
          financialBillId: this.searchForm.financialBillId,
          feeType: this.searchForm.feeType,
          orderNumber: this.searchForm.orderNumber,
        })
          .then((response) => {
            if (response.status !== 200)
              return this.$message.error("导出失败,请重试!");
            const filename = decodeURI(
              response.headers["content-disposition"]
                .split(";")[1]
                .split("= ")[1]
            );
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$message.success("导出成功!");
            document.body.removeChild(link);
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          })
          .catch(() => {
            this.$message.error("导出失败!");
            this.searchData[
              this.searchData.length - 1
            ].typeList[2].loading = false;
          });
      }
    },

    // 改变页码触发
    handleCurrentChange(val) {
      this.searchForm.current = val;
      this.getFinancialBillMeituanFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.equity-card-table {
  .search {
    margin: 10px 0;
  }
  .table {
    font-size: 14px;
  }
}
</style>
