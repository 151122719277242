<template>
  <div class="card-stored-value-table" v-loading="loading">
    <el-radio-group v-model="searchForm.feeType" @change="changeRadio">
      <el-radio-button label="1">充值</el-radio-button>
      <el-radio-button label="2"> 退余额</el-radio-button>
    </el-radio-group>
    <div class="search">
      <Search ref="search" :search-data="searchData" @searchChange="searchChange" />
    </div>
    <div class="table">
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '1'">
        充值数量：{{ total ? total : 0 }} 笔
        <span style="padding-left:20px"
          >充值金额：{{ totalPirce ? totalPirce : 0 }}元</span
        >
      </div>
      <div style="padding-bottom:10px" v-if="searchForm.feeType == '2'">
        退余额数量：{{ total ? total : 0 }} 笔
        <span style="padding-left:20px"
          >退款金额：{{ totalPirce ? totalPirce : 0 }}元</span
        >
      </div>
      <Table
        :serial-number="true"
        :key="searchForm.feeType"
        :current-value="searchForm.current"
        :header-style="headerStyle"
        :tooltipShow="true"
        :orientation="'center'"
        :height="'calc(100vh - 370px)'"
        :table-data="tableData"
        :title-list="searchForm.feeType == '1' ? titleListData : titleListData2"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import { getFinancialBillCardFee ,exportCardFeel} from "@/api/store/accounting";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      searchData: [
        {
          prop: "userPhone",
          label: "客户查询",
          type: "text",
          text: "输入手机号",
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
            {
              name: "导出",
              code: "export",
              type: "primary",
              loading:false
            },
          ],
           labelWidth:'20px'
        },
      ],
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      tableData: [],
      titleListData: [
        {
          prop: "userName",
          label: "用户",
        },
        {
          prop: "userPhone",
          label: "手机号",
        },
        {
          prop: "cardName",
          label: "卡种",
        },
        {
          prop: "payPrice",
          label: "充值金额（元）",
        },
        {
          prop: "applyTime",
          label: "充值时间",
          width: "150",
        },
        {
          prop: "storeName",
          label: "操作门店",
        },
        {
          prop: "operateUserName",
          label: "操作人",
        },
      ],
      titleListData2: [
        {
          prop: "userName",
          label: "用户",
        },
        {
          prop: "userPhone",
          label: "手机号",
        },
        {
          prop: "refundPrice",
          label: "本店通享卡退款金额（元）",
          width: "200",
        },
        {
          prop: "refundReason",
          label: "退款原因",
        },
        {
          prop: "refundTime",
          label: "退款时间",
        },
        {
          prop: "storeName",
          label: "操作门店",
        },
        {
          prop: "operateUserName",
          label: "操作人",
        },
      ],
      total: 0,
      totalPirce: 0,
      searchForm: {
        size: 10,
        current: 1,
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.status) {
          this.searchForm.feeType = value.query.status;
          this.searchForm.financialBillId = value.query.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getFinancialBillCardFeeList();
  },
  methods: {
    //  获取通享卡储值明细
    getFinancialBillCardFeeList() {
      this.loading = true;
      getFinancialBillCardFee({ ...this.searchForm })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records;
            this.totalPirce = data.data.totalSum;
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换标签触发
    changeRadio() {
      this.searchForm = {
        size: 10,
        current: 1,
        feeType: this.searchForm.feeType,
        financialBillId: this.searchForm.financialBillId,
      };
       this.$refs.search.queryInfo("resetData");
      this.getFinancialBillCardFeeList();
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.getFinancialBillCardFeeList();
      } else if (code == "reset") {
        this.searchForm = {
          financialBillId: this.searchForm.financialBillId,
          size: 10,
          current: 1,
          feeType: this.searchForm.feeType,
        };
        this.getFinancialBillCardFeeList();
      } else if (code == "export") {
        this.searchForm = {
          ...this.searchForm,
          ...formData,
          size: 10,
          current: 1,
        };
        this.searchData[this.searchData.length-1].typeList[2].loading = true;
         exportCardFeel({
          financialBillId: this.searchForm.financialBillId,
          feeType: this.searchForm.feeType,
          userPhone: this.searchForm.userPhone,
        })
          .then((response) => {
            if (response.status !== 200)
              return this.$message.error("导出失败,请重试!");
            const filename = decodeURI(
              response.headers["content-disposition"]
                .split(";")[1]
                .split("= ")[1]
            );
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$message.success("导出成功!");
            document.body.removeChild(link);
            this.searchData[this.searchData.length-1].typeList[2].loading = false;
          })
          .catch(() => {
            this.$message.error("导出失败!");
            this.searchData[this.searchData.length-1].typeList[2].loading = false;
          });
      }
    },
    // 改变页码触发
    handleCurrentChange(val) {
      this.searchForm.current = val;
      this.getFinancialBillCardFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-stored-value-table {
  .search {
    margin: 10px 0;
  }
  .table {
    font-size: 14px;
  }
}
</style>
